@font-face {
  font-family: "Black";
  src: local("RedHatDisplay-Black"), url("./assets/fonts/RedHatDisplay-Black.ttf") format("truetype");
}

@font-face {
  font-family: "Bold";
  src: local("RedHatDisplay-Bold"), url("./assets/fonts/RedHatDisplay-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "BoldItalic";
  src: local("RedHatDisplay-BoldItalic"), url("./assets/fonts/RedHatDisplay-BoldItalic.ttf") format("truetype");
}

@font-face {
  font-family: "ExtraBold";
  src: local("RedHatDisplay-ExtraBold"), url("./assets/fonts/RedHatDisplay-ExtraBold.ttf") format("truetype");
}

@font-face {
  font-family: "Medium";
  src: local("RedHatDisplay-Medium"), url("./assets/fonts/RedHatDisplay-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "SemiBold";
  src: local("RedHatDisplay-SemiBold"), url("./assets/fonts/RedHatDisplay-SemiBold.ttf") format("truetype");
}

.App {
  height: 100vh;
  background-image: url('./assets/images/blackWallpaper.svg');
  background-size: cover;
  background-repeat: no-repeat;
  font-family: "Medium";
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* BUTTONS */
.degenButton {
  color: #D6FF01 !important;
  border-color: #D6FF01 !important;
  background-color: transparent !important;
}

.degenButton:hover {
  color: #000000 !important;
  border-color: #D6FF01 !important;
  background-color: #D6FF01 !important;
}

.degenButton:active {
  color: #000000 !important;
  border-color: #D6FF01 !important;
  background-color: #D6FF01 !important;
}

/* GENERAL */
.logoVolt {
  width: 300px;
  margin-bottom: 70px;
}

.questionContainer {
  background-color: #000000;
  border: 1px solid #D6FF01;
  border-radius: 20px;
  padding: 50px;
  color: #ffffff;
}

.textInput {
  background: #FFFFFF;
  border-radius: 15px !important;
}